import React from "react";
import BackIcon from "../../../../assets/svg/BackIcon";
import { MdInfo, MdEmail } from "react-icons/md";
import { CgPhone } from "react-icons/cg";
import { RiWhatsappFill } from "react-icons/ri";
import { RiTelegramFill } from "react-icons/ri";
// import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";

import HomeContact from "../../../../assets/images/home-cont-icon.svg";
import PhoneContact from "../../../../assets/images/phone-cont-icon.svg";
import EmailContact from "../../../../assets/images/email-cont-icon.svg";
import ContactImg from "../../../../assets/images/3D-illustration/contact-us-3.png";
import { useSelector } from "react-redux";
import Footer from "../../../../containers/Footer";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Header from "../../../../containers/Header";

import Whatsapp from "../../../../assets/svg/whatsapp.svg";
import Telegram from "../../../../assets/svg/telegram.svg";
import Instagram from "../../../../assets/svg/instagram.png";
import Facebook from "../../../../assets/svg/facebook.png";

const ContactUsBefore = () => {
  const { appDetails } = useSelector((state) => state.app);
  const { isAuth } = useSelector((state) => state.auth);
  return (
    <div>
      <div>{isAuth ? <HeaderAfterLogin /> : <Header />}</div>
      <main className="main contact_sec" id="payment_page">
        <div className=" container">
          <div className="d-flex justify-content-between align-items-center mt-3 mb-3 HeadingSection">
            <h2 className="sectionTitle mb-0">Contact</h2>
            <a className="back-link" href="/">
              Back <BackIcon />
            </a>
          </div>

          <div class="social-icons contact-social">
            <ul>
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                      : "#"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  {/* <span className="notification-count">2</span> */}
                  <img src={Whatsapp} alt="Whatsapp Icon" />
                  <p>Whats App</p>
                </a>
              </li>
              <li>
                <a
                  href={appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"}
                  target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                >
                  <img src={Telegram} alt="Telegram Icon" />
                  <p> Telegram</p>
                </a>
              </li>
              <li>
                <a
                  href={
                    appDetails?.INSTATGRAM != "" ? appDetails?.INSTATGRAM : "#"
                  }
                  target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                >
                  <img src={Instagram} alt="Instagram Icon" />
                  <p>Instagram</p>
                </a>
              </li>
              <li>
                <a
                  href={appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"}
                  target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                >
                  <img src={Facebook} alt="Facebook Icon" />
                  <p>Facebook</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </main>

      <div>{isAuth ? <Footer /> : <BeforeLoginFooter />}</div>
    </div>
  );
};

export default ContactUsBefore;
