import { APP_DETAILS, BANK_DETAILS, BANNERS ,SHOW_LEFT_SIDEBAR} from "./types";

const initialState = {
  appDetails:
    localStorage.getItem("appDetails") !== null
      ? JSON.parse(localStorage.getItem("appDetails"))
      : null,
  bankDetails: null,
  banners:
    localStorage.getItem("banners") !== null
      ? JSON.parse(localStorage.getItem("banners"))
      : null,
  showLeftSideBar: false,

};

export default (state = initialState, actions) => {
  const { payload, type } = actions;
  switch (type) {
    case APP_DETAILS:
      localStorage.setItem("appDetails", JSON.stringify(payload));
      return {
        ...state,
        appDetails: payload,
      };
    case BANK_DETAILS:
      return {
        ...state,
        bankDetails: payload,
      };
    case BANNERS:
      payload.sort((a, b) => a.order - b.order);
      localStorage.setItem("banners", JSON.stringify(payload));
      return {
        ...state,
        banners: payload,
      };
      case SHOW_LEFT_SIDEBAR:
        return {
          ...state,
          showLeftSideBar: payload,
        };
    default:
      return state;
  }
};
