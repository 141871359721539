import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import HomeIcon from "./../../assets/images/games-icon/home-icon.png";
import CasinoIcon from "./../../assets/images/games-icon/casino-icon.png";
import InplayIcon from "./../../assets/images/games-icon/inplay-icon.png";
import CricketIcon from "./../../assets/images/games-icon/cricket-icon.png";
import SoccerIcon from "./../../assets/images/games-icon/soccer-icon.png";
import TennisIcon from "./../../assets/images/games-icon/tennis-icon.png";
import horseracingIcon from "./../../assets/images/games-icon/horseracing-icon.png";
import GreyhoundIcon from "./../../assets/images/games-icon/greyhoundracing-icon.png";
import Kabaddi from "./../../assets/images/games-icon/kabaddi-icon.png";
import PoliticsIcon from "./../../assets/images/games-icon/politics-icon.png";

import Whatapps from "../../assets/images/whatsapp.svg";
import Telegram from "../../assets/images/telegram.svg";
import { APP_CONST } from "../../config/const";
import LoginModal from "./authModals/LoginModal";

const LeftBarSports = () => {
  const { appDetails } = useSelector((state) => state.app);
  const navigate = useNavigate();
  const { sportsData, oddsData, showBetSlip } = useSelector(
    (state) => state.sports
  );
  const { isAuth } = useSelector((state) => state.auth);
  const [showAuthModals, setShowAuthModals] = useState({
    login: false,
    register: false,
    mobileNumber: false,
    verifyNumber: false,
    forgotpassword: false,
    resetpassword: false,
  });
  const handleModalShow = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: true }));
  };
  const handleModalClose = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: false }));
  };
  const handleEventClick = (event) => {
    navigate(
      `/detail-page/${event?.marketSlug}/${event?.matchid}/${event?.marketid}/${event?.SportId}`
    );
  };

  const tabs = [
    {
      id: 1,
      eventKey: "1",
      name: "In play",
    },
    {
      id: 2,
      eventKey: "2",
      name: "Cricket",
    },
    {
      id: 3,
      eventKey: "3",
      name: "Soccer",
    },
    {
      id: 4,
      eventKey: "4",
      name: "Tennis",
    },
  ];

  return (
    <>
      <div className="leftbarSec d-none d-md-block">
        <ul className="games_link">
          <li>
            <div
              onClick={() => {
                isAuth
                  ? navigate("/sports")
                  : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              <img src={HomeIcon} alt="Home Icon" />
              <span>Home</span>
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                isAuth
                  ? navigate("/sportsbook/Cricket", {
                      state: { selectedTab: "2" },
                    })
                  : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              <img src={CricketIcon} alt="cricket" />
              <span>Cricket</span>
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                isAuth
                  ? navigate("/sportsbook/Soccer", {
                      state: { selectedTab: "3" },
                    })
                  : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              <img src={SoccerIcon} alt="football" />
              <span>Football</span>
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                isAuth
                  ? navigate("/sportsbook/Tennis", {
                      state: { selectedTab: "4" },
                    })
                  : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              <img src={TennisIcon} alt="tennis" />
              <span>Tennis</span>
            </div>
          </li>
          <li>
            <a
              onClick={() => {
                isAuth
                  ? navigate("/sportsbook/Horse Racing")
                  : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              <img src={horseracingIcon} alt="horse racing" />
              <span>Horse Racing</span>
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                isAuth
                  ? navigate("/sportsbook/Greyhound Racing")
                  : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              <img src={GreyhoundIcon} alt="greyhound racing" />
              <span>Greyhound Racing</span>
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                isAuth
                  ? navigate("/sportsbook/Inplay")
                  : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              <img src={Kabaddi} alt="kabaddi" />
              <span>Kabaddi</span>
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                isAuth
                  ? navigate("/sportsbook/Politics")
                  : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              <img src={PoliticsIcon} alt="politics" />
              <span>Politics</span>
            </a>
          </li>
          <li>
            <div
              onClick={() => {
                isAuth
                  ? navigate("/Casino")
                  : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              <img src={CasinoIcon} alt="casino" />
              <span>Casino</span>
            </div>
          </li>
          {/* <li>
            <div
              onClick={() => {
               isAuth? navigate("/betby", { state: { selectedTab: "1" } }): handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
              }}
            >
              <img src={InplayIcon} alt="betby" />
              <span>Sports book</span>
            </div>
          </li> */}
        </ul>
        <ul className="social-contacts">
          <h6>Get in touch with us:</h6>
          <li>
            <a
              href={
                appDetails?.WHATS_APP_URL != ""
                  ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                  : "#"
              }
              target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
            >
              <img src={Whatapps} alt="Calling icon" />
              <span>Whatsapp</span>
            </a>
          </li>
          <li>
            <a
              href={appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"}
              target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
            >
              <img src={Telegram} alt="Calling icon" />
              <span>Telegram</span>
            </a>
          </li>
        </ul>
      </div>
      <LoginModal
        show={showAuthModals.login}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.LOGIN)}
        handleModalShow={handleModalShow}
      />
    </>
  );
};

export default LeftBarSports;
