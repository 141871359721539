import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import { logoutUser } from "../redux/auth/actions";

import HomeIcon from "../assets/images/games-icon/home/house.png";
import CasinoIcon from "../assets/images/games-icon/home/casino.png";
import InplayIcon from "../assets/images/games-icon/home/sportsbook.png";

import CricketIcon from "../assets/images/games-icon/home/cricket.png";
import SoccerIcon from "../assets/images/games-icon/home/footba.png";
import TennisIcon from "../assets/images/games-icon/home/tennis.png";
import horseracingIcon from "../assets/images/games-icon/home/horse.png";
import GreyhoundIcon from "../assets/images/games-icon/home/greyhound.png";
import PoliticsIcon from "../assets/images/games-icon/home/politician.png";
import refferalIcon from "../assets/images/games-icon/home/network.png";
import AcStatementIcon from "../assets/images/games-icon/home/bank-statement.png";
import LogoutIconImg1 from "../assets/images/games-icon/home/logout.png";

import WalletIcon from "../assets/images/WalletIcon.svg";
import UserIcon from "../assets/images/user-icon.svg";
import BetIcon from "../assets/images/BetIcon.png";
import AcStatement from "../assets/images/AcStatement.png";
import BonusImg from "../assets/images/bonus.png";
import refferal from "../assets/images/refferal.png";

import Whatapps from "../assets/images/whatsapp.svg";
import Telegram from "../assets/images/telegram.svg";

import crossBtn from "../assets/images/icons/close.png";
import Instagram from "../assets/svg/instagram.png";
import { handleLeftSideBar } from "../redux/app/actions";
import { APP_CONST } from "../config/const";
import LoginModal from "../views/components/authModals/LoginModal";

const MobLeftbar = () => {
  const { appDetails } = useSelector((state) => state.app);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sportsData } = useSelector((state) => state.sports);
  const { isAuth } = useSelector((state) => state.auth);
  const { showLeftSideBar } = useSelector((state) => state.app);
  const [showAuthModals, setShowAuthModals] = useState({
    login: false,
    register: false,
    mobileNumber: false,
    verifyNumber: false,
    forgotpassword: false,
    resetpassword: false,
  });
  const handleEventClick = (event) => {
    navigate(
      `/detail-page/${event?.marketSlug}/${event?.matchid}/${event?.marketid}/${event?.SportId}`
    );
  };
  const handleModalShow = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: true }));
  };

  const handleModalClose = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: false }));
  };

  const { user, balance, wallet } = useSelector((state) => state.auth);

  return (
    <div className="mobleftbarPage">
      <div className="leftbarSec">
        <ul className="games_link">
          <div className="d-flex align-items-center justify-content-between LogoMenuSidebar">
            {" "}
            <a href="/" alt="logo">
              <img src={appDetails?.LOGO_URL} alt="Logo" className="logo" />
            </a>
            <div
              className="crossBtn"
              onClick={() => {
                dispatch(handleLeftSideBar(!showLeftSideBar));
                // console.log("SideBar", showRightSideBar);
              }}
            >
              <img src={crossBtn} alt="crossBtn" />
            </div>
          </div>

          <li>
            <a
              href="#"
              onClick={() => {
                isAuth
                  ? navigate("/sportsbook/Cricket", {
                      state: { selectedTab: "2" },
                    })
                  : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              <img src={CricketIcon} alt="cricket" />
              <span>Cricket</span>
            </a>
          </li>
          <li>
            <a
              href="#"
              onClick={() => {
                isAuth
                  ? navigate("/sportsbook/Soccer", {
                      state: { selectedTab: "3" },
                    })
                  : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              <img src={SoccerIcon} alt="football" />
              <span>Footaball</span>
            </a>
          </li>
          <li>
            <a
              href="#"
              onClick={() => {
                isAuth
                  ? navigate("/sportsbook/Tennis", {
                      state: { selectedTab: "4" },
                    })
                  : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              <img src={TennisIcon} alt="tennis" />
              <span>Tennis</span>
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                isAuth
                  ? navigate("/sportsbook/Horse Racing", {
                      state: { selectedTab: "4" },
                    })
                  : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              <img src={horseracingIcon} alt="horse racing" />
              <span>Horse Racing</span>
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                isAuth
                  ? navigate("/sportsbook/Greyhound Racing", {
                      state: { selectedTab: "4" },
                    })
                  : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              <img src={GreyhoundIcon} alt="greyhound racing" />
              <span>Greyhound Racing</span>
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                isAuth
                  ? navigate("/sportsbook/Politics", {
                      state: { selectedTab: "4" },
                    })
                  : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              <img src={PoliticsIcon} alt="politics" />
              <span>Politics</span>
            </a>
          </li>
          <li>
            <a
              href="#"
              onClick={() => {
                isAuth
                  ? navigate("/Casino")
                  : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              <img src={CasinoIcon} alt="casino" />
              <span>Casino</span>
            </a>
          </li>
          {/* <li>
            <a
              href="#"
              onClick={() => {
               isAuth ? window.location.href=("/betby"):handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              <img src={InplayIcon} alt="sports book" />
              <span>Sports book</span>
            </a>
          </li> */}
        </ul>

        <ul className="social-contacts">
          <h6>Get in touch with us:</h6>
          <li>
            <a
              href={
                appDetails?.WHATS_APP_URL != ""
                  ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                  : "#"
              }
              target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
            >
              <img src={Whatapps} alt="Calling icon" />
              <span>Whatsapp</span>
            </a>
          </li>
          <li>
            <a
              href={
                appDetails?.TELEGRAM != ""
                  ? appDetails?.TELEGRAM
                  : "#"
              }
              target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
            >
              <img src={Telegram} alt="Calling icon" />
              <span>Telegram</span>
            </a>
          </li>
          <li>
            <a
              href={
                appDetails?.INSTATGRAM != ""
                  ? appDetails?.INSTATGRAM
                  : "#"
              }
              target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
            >
              <img src={Instagram} alt="Calling icon" />
              <span>Instagram</span>
            </a>
          </li>
        </ul>
      </div>
      <LoginModal
        show={showAuthModals.login}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.LOGIN)}
        handleModalShow={handleModalShow}
      />
    </div>
  );
};

export default MobLeftbar;
